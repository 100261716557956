import React, { useEffect, useState } from "react";
import staricon from "assets/staricon.svg";
import exclamation from "assets/exclamation.svg";
import Low_Complexity from "assets/Low_Complexity.svg";
import Moderate_Complexity from "assets/Moderate_Complexity.svg";
import High_Complexity from "assets/High_Complexity.svg";
import { Tooltip } from "react-tooltip";
export const QualityDifficultyOutput = ({
  analysisResult,
  refDiv,
  setDivHeight,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  useEffect(() => {
    if (refDiv?.current) {
      setDivHeight(refDiv?.current?.offsetHeight);
    }
  }, [refDiv, analysisResult]);
  const toTitleCase = (str) => {
    return str
      ?.toLowerCase()
      ?.split(" ")
      ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(" ");
  };
  const downloadCsv = () => {
    const rows = [];
    const headers = ["Category", "Score", "Explanation"];
    rows.push(headers.join(","));

    if (analysisResult?.categories) {
      for (const [key, value] of Object.entries(analysisResult.categories)) {
        const row = [
          key.replace(/_/g, " ").replace(/^\w/, (c) => c.toUpperCase()),
          value.score,
          value.explanation,
        ];
        rows.push(row.join(","));
      }
    }

    const csvContent = rows.join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", "analysis_results.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const copyText = () => {
    let textToCopy = `Overall Complexity: ${analysisResult?.overall_complexity}\n`;
    textToCopy += `Suggested Translation Type: ${toTitleCase(
      analysisResult?.suggested_translation_type
    )}\n\n`;

    if (analysisResult?.categories) {
      for (const [key, value] of Object.entries(analysisResult.categories)) {
        textToCopy += `Category: ${key
          .replace(/_/g, " ")
          .replace(/^\w/, (c) => c.toUpperCase())}\n`;
        textToCopy += `Score: ${value.score}\n`;
        textToCopy += `Explanation: ${value.explanation}\n\n`;
      }
    }

    navigator.clipboard.writeText(textToCopy).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };
  const getTooltipContent = () => {
    if (!analysisResult) return "";
    if (analysisResult.overall_complexity <= 3) {
      return "Raw Machine Translation: No human needed.";
    } else if (
      analysisResult.overall_complexity >= 4 &&
      analysisResult.overall_complexity <= 6
    ) {
      return "Machine Translation Post-Editing (MTPE): A machine translates it, then a human tidies it up.";
    } else if (analysisResult.overall_complexity > 6) {
      return "Human Translation: A professional translator handles it for the best accuracy and flow.";
    }
    return "";
  };
  return (
    <>
      <div ref={refDiv} className="w-full">
        <div className="flex gap-4 items-center ">
          <div
            className={`${
              analysisResult?.overall_complexity <= 3
                ? "bg-[#34B238]"
                : analysisResult?.overall_complexity >= 4 &&
                  analysisResult?.overall_complexity <= 6
                ? "bg-[#FFE44E] "
                : "bg-[#FF7575]"
            } rounded-md px-4 py-2 flex items-center gap-2`}
          >
            {analysisResult?.overall_complexity <= 3 ? (
              <img src={Low_Complexity} alt="low_complexity" />
            ) : analysisResult?.overall_complexity >= 4 &&
              analysisResult?.overall_complexity <= 6 ? (
              <img src={Moderate_Complexity} alt="moderate_complexity" />
            ) : (
              <img src={High_Complexity} alt="high_complexity" />
            )}

            {analysisResult?.overall_complexity && (
              <p
                className={` ${
                  analysisResult?.overall_complexity >= 4 &&
                  analysisResult?.overall_complexity <= 6
                    ? "text-[#575757] "
                    : "text-[#F5F5F5]"
                } font-bold text-[28px] font-primary`}
              >
                {analysisResult?.overall_complexity}
              </p>
            )}
          </div>
          <div>
            {analysisResult && (
              <>
                {analysisResult.overall_complexity <= 3 ? (
                  <h2 className="text-[#424242] font-opensans text-lg font-bold">
                    Low complexity
                  </h2>
                ) : analysisResult.overall_complexity >= 4 &&
                  analysisResult.overall_complexity <= 6 ? (
                  <h2 className="text-[#424242] font-opensans text-lg font-bold">
                    Moderate complexity
                  </h2>
                ) : analysisResult.overall_complexity > 6 ? (
                  <h2 className="text-[#424242] font-opensans text-lg font-bold">
                    High complexity
                  </h2>
                ) : null}
              </>
            )}

            <div className="md:flex gap-2 items-center">
              <p className="text-[#858585] text-sm font-opensans">
                Suggested Translation Type
              </p>
              <div className="text-[12px] text-[#1463FF] font-opensans font-bold flex items-center gap-2 px-2 py-1 rounded-sm bg-[#E0EBFF]">
                {/* {toTitleCase(analysisResult?.suggested_translation_type)} */}
                {analysisResult && (
                  <>
                    {analysisResult.overall_complexity <= 3 ? (
                      <span>Raw MT</span>
                    ) : analysisResult.overall_complexity >= 4 &&
                      analysisResult.overall_complexity <= 6 ? (
                      <span>MTPE</span>
                    ) : analysisResult.overall_complexity > 6 ? (
                      <span>Human Translation</span>
                    ) : null}
                  </>
                )}
                <svg
                  data-tooltip-id="translation-tooltip"
                  data-tooltip-content={getTooltipContent()}
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="13"
                  viewBox="0 0 12 13"
                  fill="none"
                >
                  <path
                    d="M5.39941 5.88794C5.39941 5.72881 5.46263 5.5762 5.57515 5.46368C5.68767 5.35116 5.84028 5.28794 5.99941 5.28794C6.15854 5.28794 6.31116 5.35116 6.42368 5.46368C6.5362 5.5762 6.59941 5.72881 6.59941 5.88794V9.48794C6.59941 9.64707 6.5362 9.79968 6.42368 9.91221C6.31116 10.0247 6.15854 10.0879 5.99941 10.0879C5.84028 10.0879 5.68767 10.0247 5.57515 9.91221C5.46263 9.79968 5.39941 9.64707 5.39941 9.48794V5.88794ZM5.99941 2.93054C5.84028 2.93054 5.68767 2.99376 5.57515 3.10628C5.46263 3.2188 5.39941 3.37141 5.39941 3.53054C5.39941 3.68967 5.46263 3.84228 5.57515 3.95481C5.68767 4.06733 5.84028 4.13054 5.99941 4.13054C6.15854 4.13054 6.31116 4.06733 6.42368 3.95481C6.5362 3.84228 6.59941 3.68967 6.59941 3.53054C6.59941 3.37141 6.5362 3.2188 6.42368 3.10628C6.31116 2.99376 6.15854 2.93054 5.99941 2.93054Z"
                    fill="#5B93FF"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 0.5C2.6862 0.5 0 3.1862 0 6.5C0 9.8138 2.6862 12.5 6 12.5C9.3138 12.5 12 9.8138 12 6.5C12 3.1862 9.3138 0.5 6 0.5ZM1.2 6.5C1.2 7.77304 1.70571 8.99394 2.60589 9.89411C3.50606 10.7943 4.72696 11.3 6 11.3C7.27304 11.3 8.49394 10.7943 9.39411 9.89411C10.2943 8.99394 10.8 7.77304 10.8 6.5C10.8 5.22696 10.2943 4.00606 9.39411 3.10589C8.49394 2.20571 7.27304 1.7 6 1.7C4.72696 1.7 3.50606 2.20571 2.60589 3.10589C1.70571 4.00606 1.2 5.22696 1.2 6.5Z"
                    fill="#5B93FF"
                  />
                </svg>
                <Tooltip
                  id="translation-tooltip"
                  place="bottom"
                  multiline={true}
                  className="z-50"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-2 w-full mt-4">
          {analysisResult?.categories &&
            Object.entries(analysisResult?.categories)
              ?.slice(0, -1)
              .map(([key, value], i) => {
                //   const [score, description] = value;
                return (
                  <div
                    key={i}
                    className={` ${
                      value.score <= 3
                        ? "bg-[#EFFAF0]"
                        : value.score >= 4 && value.score <= 6
                        ? "bg-[#FFFDF0]"
                        : "bg-[#FFF0F0]"
                    }  p-3 rounded-lg`}
                  >
                    <div
                      className="flex items-center justify-between cursor-pointer"
                      onClick={() => handleToggle(i)}
                    >
                      <p className="flex items-center gap-3 text-[#858585] text-base font-bold font-opensans">
                        <span
                          className={` ${
                            value.score <= 3
                              ? "bg-[#98E19B]"
                              : value.score >= 4 && value.score <= 6
                              ? "bg-[#FFE44E]"
                              : "bg-[#F99]"
                          } rounded-md px-2 py-2 text-[#424242] text-sm font-bold`}
                        >
                          {value.score}
                        </span>
                        {/* {key.replace(/_/g, " ")} */}
                        {key
                          .replace(/_/g, " ")
                          .replace(/^\w/, (c) => c.toUpperCase())}
                      </p>
                      <div className="flex gap-4 items-center">
                        {/* <img src={exclamation} alt="exclamation-icon" /> */}
                        <span
                          className={`text-quotetext-200 cursor-pointer ${
                            activeIndex === i
                              ? "rotate-[316deg]"
                              : "rotate-[135deg]"
                          } arrow transition-all duration-200 ease-linear`}
                        ></span>
                      </div>
                    </div>
                    {activeIndex === i && (
                      <p className="mt-2 text-sm font-normal leading-6 text-[#424242] font-opensans">
                        {value.explanation}
                      </p>
                    )}
                  </div>
                );
              })}
        </div>
        <div className="flex flex-wrap justify-end mt-4 p-3">
          <div className="flex gap-4">
            <button onClick={copyText}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.28628 0C5.86193 0 5.45497 0.168571 5.15491 0.468629C4.85485 0.768687 4.68628 1.17565 4.68628 1.6V3.2H6.28628V1.6H15.0863V10.4H13.4863V12H15.0863C15.5106 12 15.9176 11.8314 16.2177 11.5314C16.5177 11.2313 16.6863 10.8243 16.6863 10.4V1.6C16.6863 1.17565 16.5177 0.768687 16.2177 0.468629C15.9176 0.168571 15.5106 0 15.0863 0H6.28628ZM2.28628 4C1.86193 4 1.45497 4.16857 1.15491 4.46863C0.85485 4.76869 0.686279 5.17565 0.686279 5.6V14.4C0.686279 14.8243 0.85485 15.2313 1.15491 15.5314C1.45497 15.8314 1.86193 16 2.28628 16H11.0863C11.5106 16 11.9176 15.8314 12.2177 15.5314C12.5177 15.2313 12.6863 14.8243 12.6863 14.4V5.6C12.6863 5.17565 12.5177 4.76869 12.2177 4.46863C11.9176 4.16857 11.5106 4 11.0863 4H2.28628ZM2.28628 5.6H11.0863V14.4H2.28628V5.6Z"
                  fill="#5B93FF"
                />
              </svg>
            </button>
            <button onClick={downloadCsv}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="15"
                viewBox="0 0 17 15"
                fill="none"
              >
                <path
                  d="M2.72549 14.8571C2.18466 14.8571 1.66598 14.6422 1.28355 14.2598C0.901125 13.8774 0.686279 13.3587 0.686279 12.8179V9.90471C0.686279 9.67293 0.778356 9.45063 0.942253 9.28674C1.10615 9.12284 1.32844 9.03076 1.56023 9.03076C1.79201 9.03076 2.01431 9.12284 2.1782 9.28674C2.3421 9.45063 2.43418 9.67293 2.43418 9.90471V12.8179C2.43418 12.9787 2.56469 13.1092 2.72549 13.1092H14.9608C15.0381 13.1092 15.1121 13.0785 15.1668 13.0239C15.2214 12.9692 15.2521 12.8951 15.2521 12.8179V9.90471C15.2521 9.67293 15.3442 9.45063 15.5081 9.28674C15.672 9.12284 15.8943 9.03076 16.1261 9.03076C16.3578 9.03076 16.5801 9.12284 16.744 9.28674C16.9079 9.45063 17 9.67293 17 9.90471V12.8179C17 13.3587 16.7852 13.8774 16.4027 14.2598C16.0203 14.6422 15.5016 14.8571 14.9608 14.8571H2.72549Z"
                  fill="#5B93FF"
                />
                <path
                  d="M7.96919 7.50315V0.87395C7.96919 0.642164 8.06126 0.419871 8.22516 0.255974C8.38906 0.0920766 8.61135 0 8.84314 0C9.07492 0 9.29721 0.0920766 9.46111 0.255974C9.62501 0.419871 9.71709 0.642164 9.71709 0.87395V7.50315L12.0127 5.20874C12.0938 5.12764 12.19 5.0633 12.296 5.01941C12.402 4.97552 12.5156 4.95292 12.6303 4.95292C12.7449 4.95292 12.8585 4.97552 12.9645 5.01941C13.0705 5.0633 13.1667 5.12764 13.2478 5.20874C13.3289 5.28984 13.3933 5.38613 13.4372 5.49209C13.4811 5.59806 13.5037 5.71163 13.5037 5.82633C13.5037 5.94103 13.4811 6.0546 13.4372 6.16057C13.3933 6.26653 13.3289 6.36282 13.2478 6.44392L9.46073 10.231C9.37967 10.3122 9.2834 10.3766 9.17742 10.4206C9.07145 10.4645 8.95786 10.4871 8.84314 10.4871C8.72842 10.4871 8.61482 10.4645 8.50885 10.4206C8.40288 10.3766 8.30661 10.3122 8.22555 10.231L4.43843 6.44392C4.35733 6.36282 4.29299 6.26653 4.2491 6.16057C4.20521 6.0546 4.18262 5.94103 4.18262 5.82633C4.18262 5.71163 4.20521 5.59806 4.2491 5.49209C4.29299 5.38613 4.35733 5.28984 4.43843 5.20874C4.51953 5.12764 4.61582 5.0633 4.72179 5.01941C4.82775 4.97552 4.94133 4.95292 5.05602 4.95292C5.17072 4.95292 5.28429 4.97552 5.39026 5.01941C5.49623 5.0633 5.59251 5.12764 5.67361 5.20874L7.96919 7.50315Z"
                  fill="#5B93FF"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
export default QualityDifficultyOutput;
